<template>
  <div>
    <div :class="isForgotPwd ? 'd-none' : ''">
      <!-- <div class="login-access">
        <h5>Log in using</h5>
        <a
          @click.prevent="socialLoginAuthProvider('google')"
          :style="isFormSubmit ? 'pointer-events:none' : 'cursor: pointer'"
        >
          <img :src="require('@assets/images/google.svg')" alt="" />
        </a>
        <a
          @click.prevent="socialLoginAuthProvider('facebook')"
          :style="isFormSubmit ? 'pointer-events:none' : 'cursor: pointer'"
        >
          <img :src="require('@assets/images/facebook.svg')" alt="" />
        </a>
        <a href="javascript:;" :style="isFormSubmit ? 'pointer-events:none' : 'cursor: pointer'">
          <img :src="require('@assets/images/apple.svg')" alt="" />
        </a>
      </div> -->
      <div class="login-title">Log in using your email</div>
      <form @submit.prevent="login">
        <div class="auth-form">
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              placeholder="Email"
              v-model.trim="$v.email.$model"
              :readonly="isFormSubmit"
            />
            <span class="error" v-if="$v.email.$error"> Email field is required </span>
          </div>
          <div class="form-group">
            <input
              :type="passwordType"
              class="form-control"
              placeholder="Password"
              v-model.trim="$v.password.$model"
              :readonly="isFormSubmit"
            />
            <div class="icons">
              <img
                :src="require('@assets/images/eye.svg')"
                class="eye-class eye-login"
                alt=""
                @click="showPassword"
              />
            </div>
            <span class="error" v-if="$v.password.$error && !$v.password.required"
              >Password field is required .</span
            >
            <span class="error" v-if="$v.password.$error && !$v.password.minLength"
              >Password must have at least {{ $v.password.$params.minLength.min }} letters.</span
            >
          </div>
          <p class="d-block">
            <a class="border-bottom-0" role="button" @click="forgotPasswordClick">
              Forgot password?
            </a>
          </p>
          <button type="submit" class="btn btn-primary">
            <span
              v-if="isFormSubmit"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-if="!isFormSubmit" style="text-transform: none;">Log in</span>
          </button>
        </div>
      </form>

      <div>
        <p class="text-white d-block mt-3">
          If you would like to become a driver on the platform, download the app.
        </p>
        <div class="driver-access-app d-flex">
          <a :href="appleStoreURL" target="_blank">
              <img :src="require('@assets/images/Appstore.png')" width="220" alt=""
              style="margin-left: -25px;">
          </a>
          <a :href="playStoreURL" target="_blank">
              <img :src="require('@assets/images/Playstore.png')" width="220" alt=""
              style="margin-top: -5px; height: 96px;">
          </a>
        </div>
      </div>

      <p class="d-none">
        By using Drivers2u, I agree to the
        <a @click="redirectToTOS()" style="cursor: pointer" target="_blank"> terms of service</a> &
        <a @click="redirectToPP()" style="cursor: pointer" target="_blank"> privacy policy</a>
      </p>
    </div>
    <div :class="!isForgotPwd ? 'd-none' : ''">
      <form @submit.prevent="forgotPassword">
        <div class="auth-form">
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              placeholder="Email"
              v-model.trim="$v.forgotEmail.$model"
              :readonly="isForgotFormSubmit"
            />
            <span class="error" v-if="$v.forgotEmail.$error"> Email field is required </span>
          </div>
          <button type="submit" class="btn btn-primary" :disabled="isForgotFormSubmit">
            <span
              v-if="isForgotFormSubmit"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-if="!isForgotFormSubmit">Submit</span>
          </button>
          <br />
        </div>
      </form>
      <button
        class="btn btn-primary"
        style="margin-top: 6rem;text-transform: none"
        @click="loginPageClick"
        :disabled="isForgotFormSubmit"
      >
        Go to login page
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-expressions */

import { required, minLength } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import useJwt from '@/auth/jwt/useJwt';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      isFormSubmit: false,
      isForgotFormSubmit: false,
      passwordType: 'password',
      isForgotPwd: false,
      forgotEmail: '',
      customerDevicetoken: null,
      playStoreURL: process.env.VUE_APP_PLAY_STORE_URL,
      appleStoreURL: process.env.VUE_APP_APPLE_STORE_URL,
    };
  },
  validations: {
    email: {
      required,
    },
    forgotEmail: {
      required,
    },
    password: {
      required,
      minLength: minLength(3),
    },
  },
  created() {
    this.$socket.disconnect();
    if (this.$route.params.from && this.$route.params.from === 'reset-password') {
      this.forgotPasswordClick();
    }
    const isPushNotificationsEnabled = localStorage.getItem('isPushNotificationsEnabled');
    if (isPushNotificationsEnabled) {
      this.$OneSignal.getUserId().then((response1) => {
        if (response1) {
          this.customerDevicetoken = response1;
        }
      });
    }
  },
  methods: {
    ...mapActions(['connectedList', 'updateLastActive']),

    redirectToTOS() {
      window.open(process.env.VUE_APP_TOS_REDIRECT, '_blank');
    },
    redirectToPP() {
      window.open(process.env.VUE_APP_PRIVACY_POLICY_REDIRECT, '_blank');
    },
    showPassword() {
      if (this.passwordType === 'password') {
        this.passwordType = 'text';
      } else {
        this.passwordType = 'password';
      }
    },
    login() {
      this.$v.email.$touch();
      this.$v.password.$touch();
      if (!this.$v.email.$invalid && !this.$v.password.$invalid) {
        this.isFormSubmit = true;
        const loginObject = {
          email: this.email,
          password: this.password,
          ...(this.customerDevicetoken && { customer_devicetoken: this.customerDevicetoken }),
        };
        useJwt
          .login(loginObject)
          .then((response) => {
            this.$socket.setupSocketConnection();
            this.connectedList();
            this.updateLastActive();
            this.$socket.socket.onAny((eventName) => {
              if (
                response.data.data.id
                && eventName.startsWith(`sendto_${response.data.data.id}_`)
              ) {
                this.connectedList();
              }
            });
            if (
              response.data.data.roles.length === 1
              && response.data.data.roles[0].name === 'Driver'
            ) {
              this.$toast.error('Please install app for driver login');
            } else {
              // const isPushNotificationsEnabled =
              // localStorage.getItem('isPushNotificationsEnabled');
              const { token, ...userData } = response.data.data;
              useJwt.setToken(token);
              // useJwt.setRefreshToken(response.data.refreshToken);
              localStorage.setItem('userData', JSON.stringify(userData));
              this.$toast.open('Successfully logged in', 'success');
              // if (isPushNotificationsEnabled) {
              //   this.$OneSignal.getUserId().then((response1) => {
              //     if (response1) {
              //       this.storePlayerId(response1);
              //     }
              //   });
              // }
              this.$router.push({ name: 'dashboard' });
            }
          })
          .catch((error) => {
            'email' in error.response.data.data ? this.$toast.error(error.response.data.data.email[0]) : '';
            'password' in error.response.data.data ? this.$toast.error(error.response.data.data.password[0]) : '';
            'error' in error.response.data.data ? this.$toast.error(error.response.data.data.error) : '';
            // this.$refs.loginForm.setErrors(error.response.data.data.email[0]);
          })
          .finally(() => {
            this.isFormSubmit = false;
          });
      }
    },
    loginPageClick() {
      this.$emit('isForgotPwd', this.isForgotPwd);
      this.isForgotPwd = false;
    },
    forgotPasswordClick() {
      this.$emit('isForgotPwd', this.isForgotPwd);
      this.forgotEmail = '';
      this.isForgotPwd = true;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    /* eslint-disable */
    forgotPassword() {
      this.$v.forgotEmail.$touch();
      if (!this.$v.forgotEmail.$invalid) {
        this.isForgotFormSubmit = true;
        this.$http
          .post('/forget-password', { email: this.forgotEmail })
          .then((res) => {
            this.isForgotPwd = false;
            this.$emit('isForgotPwd', true);
            this.$toast.open(
              'An email has been sent with instructions on how to change your password. Please check your inbox',
              'success',
            );
          })
          .catch((error) => {
            if(error.response.data.data.email[0]){
              this.$toast.error(error.response.data.data.email[0]);
            }else{
              this.$toast.error(error.response.data.message);
            }
          })
          .finally(() => {
            this.isForgotFormSubmit = false;
          });
      }
    },
    storePlayerId() {
      if (this.customerDevicetoken) {
        this.$http
          .post('customer/setup-devicetoken', { customer_devicetoken: this.customerDevicetoken })
          .then((res) => {})
          .catch((error) => {})
          .finally(() => {});
      }
    },
    /* eslint-enable */
    // socialLoginAuthProvider(provider) {
    //   this.isFormSubmit = true;
    //   const userRole = 3;
    //   this.$toast.open({
    //     message: 'Please wait',
    //     type: 'info',
    //     duration: 6000,
    //     dismissible: true,
    //   });
    //   this.authProvider(provider, userRole).then((data) => {
    //     if (data) {
    //       const { token, ...userData } = data;
    //       useJwt.setToken(token);
    //       localStorage.setItem('userData', JSON.stringify(userData));
    //       this.storePlayerId();
    //       this.$toast.open('Successfully logged in', 'success');
    //       this.$router.push({ name: 'dashboard' });
    //     } else {
    //       this.isFormSubmit = false;
    //       this.$toast.error('Something went wrong');
    //     }
    //   });
    // },
    socialLoginAuthProvider(provider) {
      this.isFormSubmit = true;
      this.$toast.open({
        message: 'Please wait',
        type: 'info',
        duration: 6000,
        dismissible: true,
      });
      const userRole = 3;
      const self = this;
      this.$auth.authenticate(provider, userRole).then((response) => {
        console.log('ffffffffffffffff');
        self.socialLogin(provider, response);
      }).catch((err) => {
        console.log('******************************************');
        console.log(err);
        console.log(err);
      });
    },
    socialLogin(provide, response) {
      this.$http.post(`/social/${provide}`, response).then((response1) => {
        const { token, ...userData } = response1;
        useJwt.setToken(token);
        localStorage.setItem('userData', JSON.stringify(userData));
        this.storePlayerId();
        this.$toast.open('Successfully signup', 'success');
        this.$router.push({ name: 'dashboard' });
        return response1.data.token;
      }).catch((err) => {
        console.log(err);
        this.isFormSubmit = false;
        this.$toast.error('Something went wrong');
        return false;
      });
    },
  },
};
</script>
