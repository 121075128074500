import { render, staticRenderFns } from "./SignupForm.vue?vue&type=template&id=8fe6b936&scoped=true&"
import script from "./SignupForm.vue?vue&type=script&lang=js&"
export * from "./SignupForm.vue?vue&type=script&lang=js&"
import style0 from "./SignupForm.vue?vue&type=style&index=0&id=8fe6b936&prod&lang=css&"
import style1 from "./SignupForm.vue?vue&type=style&index=1&id=8fe6b936&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fe6b936",
  null
  
)

export default component.exports