<template>
  <div>
    <!-- <div class="login-access">
      <h5>Sign up using</h5>
      <a
        @click.prevent="socialLoginAuthProvider('google')"
        :style="isFormSubmit ? 'pointer-events:none' : 'cursor: pointer'"
      >
        <img :src="require('@assets/images/google.svg')" alt="" />
      </a>
      <a
        @click.prevent="socialLoginAuthProvider('facebook')"
        :style="isFormSubmit ? 'pointer-events:none' : 'cursor: pointer'"
      >
        <img :src="require('@assets/images/facebook.svg')" alt="" />
      </a>
      <a href="javascript:;" :style="isFormSubmit ? 'pointer-events:none' : 'cursor: pointer'">
        <img :src="require('@assets/images/apple.svg')" alt="" />
      </a>
    </div> -->
    <!-- <div class="login-title">Sign up using your email</div> -->
    <form @submit.prevent="signup">
      <div class="auth-form">
        <div class="form-group">
          <input
            type="name"
            class="form-control"
            placeholder="First name"
            v-model.trim="$v.name.$model"
            :readonly="isFormSubmit"
          />
          <span class="error" v-if="$v.name.$error && !$v.name.required">
            first name field is required
          </span>
          <span class="error" v-else-if="$v.name.$error && !$v.name.isNameValid">
            please enter valid first name
          </span>
        </div>
        <div class="form-group">
          <input
            type="name"
            class="form-control"
            placeholder="Last name"
            v-model.trim="$v.lastName.$model"
            :readonly="isFormSubmit"
          />
          <span class="error" v-if="$v.lastName.$error && !$v.lastName.required">
            last name field is required
          </span>
          <span class="error" v-else-if="$v.lastName.$error && !$v.lastName.isNameValid">
            please enter valid last name
          </span>
        </div>
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            placeholder="Email"
            v-model.trim="$v.email.$model"
            :readonly="isFormSubmit"
          />
          <span class="error" v-if="$v.email.$error"> email field is required </span>
        </div>
        <div class="form-group">
            <VuePhoneNumberInput
              v-model.trim="$v.mobile.$model"
              :disabled="isFormSubmit"
              :default-country-code=defaultCountryCode
              @update="changeMobile"
            />
            <span class="error" v-if="$v.mobile.$error && $v.mobile.required">
              phone number field is required </span
            ><br />
            <span class="error" v-if="isPhoneValidError"> please enter valid phone </span>
        </div>
        <div class="form-group">
          <input
            :type="passwordType"
            class="form-control"
            placeholder="Password"
            v-model.trim="$v.password.$model"
            :readonly="isFormSubmit"
            autocomplete="off"
          />
          <div class="icons">
            <img
              :src="require('@assets/images/eye.svg')"
              class="eye-class eye-login"
              alt=""
              @click="showPassword"
            />
          </div>
          <password
            v-model="$v.password.$model"
            :strength-meter-only="true"
            class="signup-border"
          />
          <span class="error" v-if="$v.password.$error && !$v.password.required"
            >password field is required.<br></span
          >
          <!-- <span class="error" v-if="$v.password.$error && !$v.password.minLength"
            >password must have at least {{ $v.password.$params.minLength.min }} letters.</span
          >

          <span class="error" v-if="$v.password.$error && !$v.password.maxLength">
            password must have minimum 8 maximum {{ $v.password.$params.maxLength.max }} letters.
          </span> -->

          <span class="error"
            v-if="$v.password.$error &&
                  !$v.password.valid ||
                  !$v.password.minLength ||
                  !$v.password.maxLength"
          >
            Password must be between 8 and 16 characters long,
            and contain at lease one of each of the following:
            lowercase letters, numbers, and special characters.
          </span>
        </div>
        <div class="form-group">
          <input
            :type="confirmPasswordType"
            class="form-control"
            placeholder="Confirm password"
            v-model.trim="$v.c_password.$model"
            :readonly="isFormSubmit"
          />
          <div class="icons">
            <img
              v-if="bothPasswordMatched"
              :src="require('@assets/images/password_matched.svg')"
              class="eye-class"
              alt=""
            />
            <img
              :src="require('@assets/images/eye.svg')"
              class="eye-class eye-login"
              alt=""
              @click="showConfirmPasswordType"
            />
          </div>
          <span class="error" v-if="$v.c_password.$error && !$v.c_password.required">
            confirm password field is required .</span
          >
          <br v-if="$v.c_password.$error && !$v.c_password.required" />
          <span class="error" v-if="$v.c_password.$error && !$v.c_password.minLength">
            confirm password must have at least
            {{ $v.c_password.$params.minLength.min }} letters.</span
          >
          <span class="error" v-if="$v.password.$error && !$v.password.maxLength">
            password must have minimum 8 maximum {{ $v.password.$params.maxLength.max }} letters.
            </span>
          <br v-if="$v.c_password.$error && !$v.c_password.minLength" />
          <span class="error" v-if="$v.c_password.$error && !$v.c_password.sameAsPassword">
            confirm password must be same as password .
          </span>
        </div>
        <div class="d-flex">
          <input
            class="form-check-input me-2"
            type="checkbox"
            value=""
            id="flexCheckChecked"
            checked
            v-model.trim="$v.agreewithTerms.$model"
          />
          <p class="d-block">
            By using Drivers2u, I agree to the
            <a @click="redirectToTOS()" style="cursor: pointer" target="_blank"> terms of service
            </a> &
            <a @click="redirectToPP()" style="cursor: pointer" target="_blank"> privacy policy</a>
          </p>
        </div>
        <span class="error" v-if="$v.agreewithTerms.$error">
          please accept terms of service & privacy policy </span
        ><br />
        <button type="submit" class="btn btn-primary">
          <span
            v-if="isFormSubmit"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-if="!isFormSubmit" style="text-transform: none;">Sign up</span>
        </button>
      </div>
    </form>
    <div>
      <p class="text-white d-block mt-3">
        If you would like to become a driver on the platform, download the app.
      </p>
      <div class="driver-access-app d-flex">
        <a :href="appleStoreURL" target="_blank">
            <img :src="require('@assets/images/Appstore.png')" width="220" alt=""
            style="margin-left: -25px;">
        </a>
        <a :href="playStoreURL" target="_blank">
            <img :src="require('@assets/images/Playstore.png')" width="220" alt=""
            style="margin-top: -5px; height: 96px;">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import useJwt from '@/auth/jwt/useJwt';
import { required, sameAs, minLength, helpers, maxLength } from 'vuelidate/lib/validators';
import Password from '@/views/pages/PasswordStrengthComponents/PasswordStrength.vue';

// const isNameValid = helpers.regex('isNameValid', /^[a-z]*$/i);
const isNameValid = helpers.regex('isNameValid', /^([a-zA-Z]+\s)*[a-zA-Z]+$/);

export default {
  name: 'CustomerSignup',
  components: { VuePhoneNumberInput, Password },
  data() {
    return {
      name: '',
      lastName: '',
      email: '',
      password: '',
      c_password: '',
      isFormSubmit: false,
      passwordType: 'password',
      confirmPasswordType: 'password',
      customerDevicetoken: null,
      agreewithTerms: '',
      mobile: '',
      countryCode: '+61',
      isPhoneValidError: false,
      isPhoneValid: false,
      defaultCountryCode: 'AU',
      playStoreURL: process.env.VUE_APP_PLAY_STORE_URL,
      appleStoreURL: process.env.VUE_APP_APPLE_STORE_URL,
    };
  },
  watch: {},
  created() {
    const isPushNotificationsEnabled = localStorage.getItem('isPushNotificationsEnabled');
    if (isPushNotificationsEnabled) {
      this.$OneSignal.getUserId().then((response1) => {
        if (response1) {
          this.customerDevicetoken = response1;
        }
      });
    }
    this.ipLookUp();
  },
  validations: {
    name: {
      required,
      isNameValid
    },
    lastName: {
      required,
      isNameValid
    },
    email: {
      required,
    },
    mobile: {
      required,
    },
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(16),
      // containsLowercase: function (value) {
      //   return /[a-z]/.test(value);
      // },
      // containsNumber: function (value) {
      //   return /[0-9]/.test(value);
      // },
      // containsSpecial: function (value) {
      //   return /[#?!@$%^&*-]/.test(value);
      // },
      valid(value) {
      const containsLowercase = /[a-z]/.test(value);
        const containsNumber = /[0-9]/.test(value);
        const containsSpecial = /[#?!@$%^&*-]/.test(value);
        return containsLowercase && containsNumber && containsSpecial;
      }
    },
    c_password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(16),
      sameAsPassword: sameAs('password'),
    },
    agreewithTerms: {
      required,
      sameAs: sameAs(() => true),
    },
  },
  computed: {
    bothPasswordMatched() {
      return this.password.length > 7 && this.password === this.c_password;
    },
  },
  methods: {
    ipLookUp() {
      this.$http.get('http://ip-api.com/json')
        .then((response) => {
          if (response && response.data && response.data.countryCode) {
            this.defaultCountryCode = response.data.countryCode;
          }
        })
        .catch((data, status) => {
        });
    },
    redirectToTOS() {
      window.open(process.env.VUE_APP_TOS_REDIRECT, '_blank');
    },
    redirectToPP() {
      window.open(process.env.VUE_APP_PRIVACY_POLICY_REDIRECT, '_blank');
    },
    showPassword() {
      if (this.passwordType === 'password') {
        this.passwordType = 'text';
      } else {
        this.passwordType = 'password';
      }
    },
    changeMobile($event) {
      this.isPhoneValid = $event.isValid;
      if (this.isPhoneValidError && this.isPhoneValid) {
        this.isPhoneValidError = !this.isPhoneValid;
      }
      this.countryCode = `+${$event.countryCallingCode}`;
    },
    showConfirmPasswordType() {
      if (this.confirmPasswordType === 'password') {
        this.confirmPasswordType = 'text';
      } else {
        this.confirmPasswordType = 'password';
      }
    },
    signup() {
      this.isPhoneValidError = !this.isPhoneValid;
      this.$v.$touch();
      if (!this.$v.$invalid && !this.isPhoneValidError) {
        this.isFormSubmit = true;
        this.$http
          .post('userRegister', {
            name: this.name,
            last_name: this.lastName,
            email: this.email,
            password: this.password,
            password_confirmation: this.c_password,
            mobile: this.mobile.replace(/ /g, ''),
            countryCode: this.countryCode,
            userRole: 3,
          })
          .then((response) => {
            const { token, ...userData } = response.data.data;
            useJwt.setToken(token);
            // useJwt.setRefreshToken(response.data.refreshToken);
            localStorage.setItem('userData', JSON.stringify(userData));
            this.storePlayerId();
            this.$toast.open('Successfully signup', 'success');
            this.$router.push({ name: 'dashboard' });
          })
          .catch((error) => {
            this.$toast.error(error.response.data.data.email[0]);
            this.$toast.error(error.response.data.data.password[0]);
            this.$refs.loginForm.setErrors(error.response.data.error);
          })
          .finally(() => {
            this.isFormSubmit = false;
          });
      }
    },
    socialLoginAuthProvider(provider) {
      this.isFormSubmit = true;
      this.$toast.open({
        message: 'Please wait',
        type: 'info',
        duration: 6000,
        dismissible: true,
      });
      const userRole = 3;
      this.authProvider(provider, userRole).then((data) => {
        if (data) {
          const { token, ...userData } = data;
          useJwt.setToken(token);
          localStorage.setItem('userData', JSON.stringify(userData));
          this.storePlayerId();
          this.$toast.open('Successfully signup', 'success');
          this.$router.push({ name: 'dashboard' });
        } else {
          this.isFormSubmit = false;
          this.$toast.error('Something went wrong');
        }
      });
    },
    // socialLoginAuthProvider(provider) {
    //   this.isFormSubmit = true;
    //   this.$toast.open({
    //     message: 'Please wait',
    //     type: 'info',
    //     duration: 6000,
    //     dismissible: true,
    //   });
    //   const userRole = 3;
    //   this.$http
    //     .get(`/sociallogin/${provider}`)
    //     .then((res) => {
    //       console.log(res);
    //     })
    //     .catch((error) => {
    //       this.$toast.error(error.response.data.message);
    //     })
    //     .finally(() => {});
    // },
    storePlayerId() {
      if (this.customerDevicetoken) {
        this.$http
          .post('customer/setup-devicetoken', { customer_devicetoken: this.customerDevicetoken })
          .then((res) => {})
          .catch((error) => {})
          .finally(() => {});
      }
    },
  },
};
</script>
<style>
  body{
  background-color: #00A6E3 !important;
}
</style>
<style lang="scss" scoped>
.vue-phone-number-input {
  border-bottom: 1px solid rgba(250, 250, 250, 0.3);
  // ::v-deep .select-country-container {
  //   max-width: 81px;
  // }
  // ::v-deep .select-country-container[data-v-19c9a1c7] {
  //   width: 80px !important;
  //   min-width: 80px !important;
  //   max-width: 80px !important;
  // }
  ::v-deep .country-selector__input {
    display: none;
  }
  ::v-deep .country-selector__label {
    display: none;
  }
  ::v-deep label.input-tel__label {
    margin-top: -16px;
    margin-left: -14px;
    color: #ffffff !important;
  }
  ::v-deep .input-tel__input{
    padding: 0 !important;
  }
  ::v-deep .input-tel__input::placeholder{
    color: rgba(255, 255, 255, 0.5) !important;
  }
  ::v-deep .country-selector__country-flag[data-v-46e105de]{
    top: 15px;
  }

  ::v-deep .input-tel__input {
    background-color: unset !important;
    border: unset !important;
    caret-color: dodgerblue;
    box-shadow: unset !important;
    color: #ffffff !important;
    font-size: 16px !important;
    cursor: default !important;
    caret-color: #ffffff !important;
  }
  ::v-deep .input-tel__label {
    color: rgba(250, 250, 250, 0.3) !important;
    cursor: default !important;
  }
  ::v-deep .country-selector__toggle[data-v-46e105de] {
    // right: 21px !important;
  }
  ::v-deep .select-country-container[data-v-19c9a1c7] {
    // min-width: 0px !important;
  }
  ::v-deep .select-country-container[data-v-19c9a1c7] {
    width: 0 !important;
    min-width: 0 !important;
    max-width: 120px !important;
    flex: 0 0 60px;
  }
}
</style>
