<template>
  <div class="auth-screen cust-login">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="auth-fold">
            <div class="auth-body">
              <div class="auth-left">
                <figure>
                  <img :src="require('@assets/images/customer-logo.svg')" alt="logo" />
                </figure>
                <h5 v-if="currentPage == 'login' && !isShowForgotPwd" style="visibility:hidden">
                  Customer Login</h5>
                <h5 v-if="currentPage == 'login' && isShowForgotPwd" style="visibility:hidden">
                  Forgot Password</h5>
                <h5 v-if="currentPage == 'signup'" style="visibility:hidden">Customer Signup</h5>
                <div class="cl-image">
                  <img :src="require('@assets/images/driver-signup-main.svg')" alt="" />
                </div>
                <p class="d-none">
                  By using Drivers2u, I agree to the
                  <a @click="redirectToTOS()" style="cursor: pointer" target="_blank">
                    terms of service</a
                  >
                  &
                  <a @click="redirectToPP()" style="cursor: pointer" target="_blank">
                    privacy policy</a
                  >
                </p>
              </div>
              <!-- put here -->

              <div class="auth-right">
                <div class="auth-header" v-if="!isShowForgotPwd">
                  <a
                    class="auth-link"
                    :class="{ active: currentPage == 'signup' }"
                    @click="currentPage = 'signup'"
                    style="cursor: pointer;text-transform: none;"
                  >
                    Sign up
                  </a>
                  <a
                    class="auth-link"
                    :class="{ active: currentPage == 'login' }"
                    @click="currentPage = 'login'"
                    style="cursor: pointer;text-transform: none;"
                  >
                    Log in
                  </a>
                </div>
                <div class="auth-header" v-if="isShowForgotPwd">
                  <a class="auth-link active"  style="pointer-events: none !important;
                  text-transform: none;">
                    Forgot password </a>
                </div>
                <login-form v-if="currentPage == 'login'" @isForgotPwd="isForgotPwd($event)" />
                <signup-form v-if="currentPage == 'signup'" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from './CustomerAccountComponents/LoginForm.vue';
import SignupForm from './CustomerAccountComponents/SignupForm.vue';

export default {
  components: { LoginForm, SignupForm },
  name: 'Account',
  data() {
    return {
      currentPage: 'login',
      isShowForgotPwd: false,
      setheight: 0,
    };
  },
  created() {
    this.setheight = window.innerHeight;
  },
  methods: {
    isForgotPwd(status) {
      this.isShowForgotPwd = !status;
    },
    redirectToTOS() {
      window.open(process.env.VUE_APP_TOS_REDIRECT, '_blank');
    },
    redirectToPP() {
      window.open(process.env.VUE_APP_PRIVACY_POLICY_REDIRECT, '_blank');
    },
  },
};
</script>
